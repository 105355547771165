<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-sek')">
                    <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" class="break-normal custom-textarea" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            infoContent: `Redovisningprinciper ska anges som noter. Standardinställningen är att de numreras som Not 1. Inställningen för det ligger under menyn Årshandlingar, 
            <br />Allmänna inställningar för årshandlingar. 
            <br />
            <br />ÅRL 5 kap. Noter
            <br />K2 (BFNAR 2016:10) kapitel 18 - Noter`,
            isInitialInputTextSet: false,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        InfoButton: () => import("../../managementStatement/components/InfoButton.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 1,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.localInputText = this.inputText;
            this.isInitialInputTextSet = false;
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: 'Sparat "Redovisnings- och värderingsprinciper"' });
        },
        closeModal() {
            const load = "loading-sek";
            const isNewData = this.inputText !== this.localInputText;
            this.$emit("close", { load: load, isNewData: isNewData });
            this.toggleEdit();
        },
    },
    computed: {
        localInputText: {
            get() {
                return this.inputText;
            },
            set(newVal) {
                this.$emit("update:text", newVal);
            },
        },
    },
};
</script>
